<template>
  <div class="newSignUp-container">
    <section class="signUpPage-wrapper">
      <div class="w-100">
        <form @submit.prevent="submitFormLocal" class="new-signUpPage-form">
          <!-- Your Name -->
          <div class="form-group">
            <label for="name" class="f-poppins-medium">{{
              $store.state.newSignUpForm?.fullNameLabel
            }}</label>
            <input
              type="text"
              id="name"
              :placeholder="$store.state.newSignUpForm?.fullNamePlaceholder"
              v-model="FormData.full_name"
              @blur="validateName"
              :class="{
                'new-signUpPage-form__is-invalid': FromErrorFlags.full_name,
              }"
              required
            />
          </div>

          <!-- Email -->
          <div class="form-group">
            <label for="email" class="f-poppins-medium">{{
              $store.state.newSignUpForm?.emailLabel
            }}</label>
            <input
              type="email"
              id="email"
              :placeholder="$store.state.newSignUpForm?.emailPlaceholder"
              v-model="FormData.email"
              @blur="validateEmail"
              :class="{
                'new-signUpPage-form__is-invalid': FromErrorFlags.email,
              }"
              required
            />
          </div>

          <!-- Phone -->
          <div class="form-group">
            <label for="phone" class="f-poppins-medium">{{
              $store.state.newSignUpForm?.phoneLabel
            }}</label>
            <input
              type="tel"
              id="phone"
              :placeholder="$store.state.newSignUpForm?.phonePlaceholder"
              v-model="FormData.number"
              @blur="validatePhone"
              :class="{
                'new-signUpPage-form__is-invalid': FromErrorFlags.number,
              }"
              required
            />
          </div>

          <!-- Business Website or Social -->
          <div class="form-group">
            <label for="website" class="f-poppins-medium">{{
              $store.state.newSignUpForm?.websiteLabel
            }}</label>
            <input
              type="text"
              id="website"
              :placeholder="$store.state.newSignUpForm?.websitePlaceholder"
              v-model="FormData.instagram_url"
              @blur="validateWebsite"
            />
          </div>

          <!-- Submit Button -->
          <button type="submit" class="w-100 f-poppins-medium">
            {{ $store.state.newSignUpForm?.submitButton }}
          </button>

          <!-- Sign In Link -->
          <p class="text-center">
            {{ $store.state.newSignUpForm?.signInText }}
            <router-link
              :to="'/' + $store.state.newSignUpForm?.signInLink"
              class="f-poppins-medium"
              >{{ $store.state.newSignUpForm?.signInLinkText }}</router-link
            >.
          </p>
        </form>
      </div>
      <div class="signUpPage-wrapper__right-column">
        <div>
          <h3 class="f-poppins-semiBold">
            {{ $store.state.newSignUpForm?.getDemoTitle }}
          </h3>
          <p class="f-poppins-regular">
            {{ $store.state.newSignUpForm?.getDemoDescription }}
          </p>
        </div>
        <img src="/images/sign up new/1.jpg" alt="" />
      </div>
    </section>
  </div>
</template>
<script>
import { axiosAPI } from "@/axiosClient";
import { toast } from "vue3-toastify";

export default {
  name: "BrandsForm1",

  data() {
    return {
      FormData: {
        full_name: "",
        email: "",
        number: "",
        instagram_url: "",
      },
      FromErrorFlags: {
        full_name: false,
        email: false,
        number: false,
        instagram_url: false,
      },
      FromErrorMessages: {
        full_name: [],
        email: [],
        number: [],
        instagram_url: [],
      },
    };
  },

  computed: {
    // Retrieve newSignUpForm from the Vuex store
    newSignUpForm() {
      return this.$store.state.newSignUpForm; // Adjust this based on your store structure
    },
  },

  watch: {
    // Watch for changes in newSignUpForm and log it

    "FormData.full_name"() {
      this.FromErrorFlags.full_name = false;
      this.FromErrorMessages.full_name = [];
    },
    "FormData.email"() {
      this.FromErrorFlags.email = false;
      this.FromErrorMessages.email = [];
    },
    "FormData.number"() {
      this.FromErrorFlags.number = false;
      this.FromErrorMessages.number = [];
    },
    "FormData.instagram_url"() {
      this.FromErrorFlags.instagram_url = false;
      this.FromErrorMessages.instagram_url = [];
    },
  },

  created() {
    // Dispatch an action to fetch the newSignUpForm data from the store if needed
    this.$store.dispatch("getNewSignUpForm");
  },

  methods: {
    submitFormLocal() {
      // Reset error messages
      this.FromErrorMessages = {
        full_name: [],
        email: [],
        number: [],
        instagram_url: [],
      };
      let errorFlag = false;

      if (this.FormData.full_name.trim() == "") {
        this.FromErrorFlags.full_name = true;
        this.FromErrorMessages.full_name.push("Your name cannot be empty.");
        errorFlag = true;
      }

      if (this.FormData.email.trim() == "") {
        this.FromErrorFlags.email = true;
        this.FromErrorMessages.email.push("Email cannot be empty.");
        errorFlag = true;
      } else if (!this.validateEmailFormat(this.FormData.email)) {
        this.FromErrorFlags.email = true;
        this.FromErrorMessages.email.push("Invalid email format.");
        errorFlag = true;
      }

      if (this.FormData.number.trim() == "") {
        this.FromErrorFlags.number = true;
        this.FromErrorMessages.number.push("Phone number cannot be empty.");
        errorFlag = true;
      }

      if (this.FormData.instagram_url.trim() == "") {
        this.FromErrorFlags.instagram_url = true;
        this.FromErrorMessages.instagram_url.push(
          "Instagram URL cannot be empty."
        );
        errorFlag = true;
      }

      if (errorFlag) {
        return;
      }

      // Store UTM parameters if they exist in local storage
      if (localStorage.getItem("utm_source")) {
        this.FormData["source"] =
          localStorage.getItem("utm_source") + "-new-form";
      } else {
        this.FormData["source"] = "new-form";
      }

      if (localStorage.getItem("utm_medium")) {
        this.FormData["utm_medium"] =
          localStorage.getItem("utm_medium") + "-new-form";
      } else {
        this.FormData["utm_medium"] = "new-form";
      }

      if (localStorage.getItem("utm_campaign")) {
        this.FormData["utm_campaign"] =
          localStorage.getItem("utm_campaign") + "-new-form";
      } else {
        this.FormData["utm_campaign"] = "new-form";
      }

      // Submit form data
      axiosAPI
        .post(`v1/brands/web/min-signup`, this.FormData)
        .then((response) => {
          if (response.data && response.data.status) {
            toast.success("Sign-up successful!"); // Success message
            this.resetForm();
          } else {
            toast.error("Sign-up failed! Please try again.");
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            let error_msgs = [];
            for (const error_msg in error.response.data) {
              if (error.response.data[error_msg].length > 0) {
                error_msgs.push(...error.response.data[error_msg]);
                this.FromErrorFlags[error_msg] = true;
              }
            }
            toast.info(error_msgs[0]);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        });
    },

    validateEmailFormat(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format regex
      return re.test(String(email).toLowerCase());
    },

    resetForm() {
      // Reset form fields
      this.FormData = {
        full_name: "",
        email: "",
        number: "",
        instagram_url: "",
      };
    },
  },
};
</script>
<style>
.signUpPage-wrapper {
  max-width: 72.125rem;
  width: 96%;
  background-color: #fff;
  margin: 14.125rem auto 9rem auto;
  border-radius: 18px;
  display: flex;
  justify-content: space-between;
}

.new-signUpPage-form {
  /* width: 100%;*/
  max-width: 450px;
  margin: 90px auto 0 auto;
  font-size: 15px;
}
.new-signUpPage-form .form-group {
  margin-bottom: 24px;
}
.new-signUpPage-form .form-group {
  margin-bottom: 24px;
}
.new-signUpPage-form .form-group label {
  color: #4f4f4f;
}
.new-signUpPage-form .form-group input {
  display: block;
  width: 100%;
  padding: 10px 25px 12px 25px;
  border-radius: 11px;
  border: 1px solid #e4e4e4;
  background-color: #f3f3f3;
}
.new-signUpPage-form .form-group input::placeholder {
  color: #484848;
  font-size: 15px;
}

/* fallback for other browsers */

/* Fallback for older WebKit browsers (Safari, Chrome) */
.new-signUpPage-form .form-group input::-webkit-input-placeholder {
  color: #484848;
  font-size: 15px;
}

/* Fallback for Firefox */
.new-signUpPage-form .form-group input::-moz-placeholder {
  color: #484848;
  font-size: 15px;
}

/* Fallback for Internet Explorer 10 and 11 */
.new-signUpPage-form .form-group input:-ms-input-placeholder {
  color: #484848;
  font-size: 15px;
}

/* Fallback for Microsoft Edge */
.new-signUpPage-form .form-group input::-ms-input-placeholder {
  color: #484848;
  font-size: 15px;
}

.new-signUpPage-form button {
  border: none;
  background: #626262;
  color: #ffffff;
  padding-block: 11px;
  margin-block: 21px;
  border-radius: 11px;
}

.new-signUpPage-form p {
  color: #6b6b6b;
}
.new-signUpPage-form a {
  color: #00a4b6;
}
.signUpPage-wrapper__right-column {
  position: relative;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.signUpPage-wrapper__right-column img {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.signUpPage-wrapper__right-column div {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 54px;
}
.signUpPage-wrapper__right-column div h3,
.signUpPage-wrapper__right-column div p {
  color: #434343;
}
.signUpPage-wrapper__right-column div h3 {
  font-size: 24px;
}

.signUpPage-wrapper__right-column div p {
  font-size: 18px;
}

/* error */

.new-signUpPage-form__is-invalid {
  outline: 1px solid red;
}

@media (max-width: 1200px) {
  .signUpPage-wrapper__right-column {
    display: none;
  }
  .signUpPage-wrapper {
    max-width: 650px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    padding-inline: 20px;
    padding-bottom: 40px;
  }
  .new-signUpPage-form {
    /* width: 100%;*/
    max-width: 550px;
  }
}

@media (max-width: 768px) {
  .signUpPage-wrapper {
    margin-top: 130px;
    padding-bottom: 20px;
  }
}

/*axiosAPI
        .post("v1/brands/web/signup", dataToSend) // Ensure you send the correct data structure
        .then((response) => {
          if (response.data && response.data.status) {
            toast.success("Form submitted successfully!");
          } else {
            toast.error("Form submission failed!");
          }
        })
        .catch((error) => {
          toast.error("An error occurred during form submission.");
          if (error.response && error.response.data) {
            const error_msgs = [];
            for (const error_msg in error.response.data) {
              if (error.response.data[error_msg].length > 0) {
                error_msgs.push(...error.response.data[error_msg]);
                this.FromErrorFlags[error_msg] = true;
              }
            }
            if (this.$store.state.errorMessges) {
              toast.info(this.$store.state.errorMessges[error_msgs[0]]);
            }
          }
        });*/
</style>
