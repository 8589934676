<template>
  <div :class="$store.state.isRtl ? 'rtl' : ''">
    <layout />
  </div>
</template>
<script>
import layout from "./components/layout/index.vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import { useStore } from "vuex";
export default {
  name: "App",
  components: {
    layout,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    function openIntercom(data) {
      if (data.intercom_open == 1) window.Intercom("show");
    }
    onMounted(async () => {
      await router.isReady();
      let utm = {};

      if (route.query.utm_source) {
        utm.utm_source = route.query.utm_source;
      }
      if (route.query.utm_medium) {
        utm.utm_medium = route.query.utm_medium;
      }
      if (route.query.utm_campaign) {
        utm.utm_campaign = route.query.utm_campaign;
      }
      store.dispatch("getUTM", utm);

      if (route.query.utm_source) {
        localStorage.setItem("utm_source", route.query.utm_source);
      }
      if (route.query.utm_medium) {
        localStorage.setItem("utm_medium", route.query.utm_medium);
      }
      if (route.query.utm_campaign) {
        localStorage.setItem("utm_campaign", route.query.utm_campaign);
      }
      await store.dispatch("getGeneralSettings", openIntercom);
    });
  },
};
</script>

<style lang="scss">
@import "./styles/index.scss";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/css/bootstrap.rtl.min.css";
</style>
