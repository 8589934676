<template>
  <TsNavbar
    :NavBarCustomClasses="NavBarCustomClasses"
    @updateLang="updateLanguage"
  />
  <main :class="['ts-wrapper', { 'signup-bg': isSignupNew }]">
    <RouterView @data-navBarCustomClasses="receiveDataFromChild" />
  </main>
  <TsFooter />
</template>

<script>
import { useRoute } from "vue-router";
import TsNavbar from "./TsNavbar.vue";
import TsFooter from "./TsFooter.vue";
export default {
  name: "App",
  components: {
    TsNavbar,
    TsFooter,
  },

  data() {
    return {
      NavBarCustomClasses: "",
    };
  },
  computed: {
    // Check if the current route is '/signupnew'
    isSignupNew() {
      const route = useRoute();
      return route.path === "/brand-signups";
    },
  },
  methods: {
    receiveDataFromChild(data) {
      this.NavBarCustomClasses = data;
    },
  },
};
</script>
<style lang="scss">
.ts-wrapper {
  // Default styles for ts-wrapper, if any

  &.signup-bg {
    background-color: #f3f3f3; // Conditional background color
    display: flex; /* new modification */
    flex-direction: column;
    justify-content: center;
  }
}
</style>
